html {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: 0;
  padding: 0;
  font-family: "ToyotaType", Trebuchet, Tahoma, Arial, sans-serif;
  font-size: 100%;
  background-color: #000;
  overscroll-behavior: none;
  user-select: none;
}

body::-webkit-scrollbar {
  display: none;
}

#root {
  width: 100%;
  height: 100%;
  padding: 0;
  /* box-sizing: border-box; */
}

canvas {
  pointer-events: auto;
  overflow: hidden;
}

/* .canvas {
  height: -webkit-fill-available;
  min-height: 100%;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.canvas:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

.drei-html {
  overflow: visible;
}

.drei-html * {
  overflow: visible;
} */

.leva-c-kWgxhW {
  margin-top: 100px;
  max-height: 600px;
  width: auto;
  opacity: 0.8;
  z-index: 99999999999;
}
.leva-c-kWgxhW-bCBHqk-fill-false {
  width: auto;
  max-height: 600px;
  opacity: 0.8;
  z-index: 99999999999;
}

#modal-root {
  left: 0;
  top: 0;
  /* background-color: black; */
  color: white;
  position: fixed;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 9999999999;
}
