.explore-btn {
  transition: all 0.3s ease-in-out;
  color: #000;
  width: 192px;
  height: 44px;
  border-radius: 22px;
  border: none;
  pointer-events: auto;
}
.explore-btn:hover {
  scale: 1.1;
}

.scrollmagic-pin-spacer {
  padding-bottom: 0;
  max-height: 100%;
}

.text_container {
  position: absolute;
  z-index: 1910;
  left: 15%;
  color: rgba(256, 256, 256, 0.5);
  font-size: 20px;
  position: absolute;
  z-index: 2;
}

.title_container {
  width: 100vw;
  height: 100%;
  position: absolute;
  z-index: 0;
}

.title_wrapper {
  position: absolute;
  width: 100%;
  z-index: 1911;
  transform: translate(-50%, -50%);
  left: 50%;
  font-size: 2.3rem;
  color: rgba(256, 256, 256, 0.5);
  white-space: normal;
  text-align: center;
  line-height: 1;
  position: absolute;
  z-index: 2;
}

.button_wrapper {
  position: absolute;
  z-index: 1910;
  left: 50%;
  pointer-events: none;
  transform: translate(-50%, -50%);
  z-index: 2;
}

@media (max-width: 1025px) {
  .text_container {
    left: 5%;
    font-size: 10px;
  }

  .title_wrapper {
    font-size: 1.3rem;
    width: 100%;
  }
  .explore-btn {
    width: 144px;
    height: 32px;
    border-radius: 16px;
  }
}
